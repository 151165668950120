<template>
    <section class="detail-report-quiz text-white">
        <div class="report-quiz">
            <div class="header w-100 container-fluid mt-4 mx-0">
                <div class="card mt-4">
                    <div v-if="dataEvents?.templateLookUp != null && dataEvents?.templateLookUp.length > 0" class="d-flex mx-4 my-4 align-items-center">
                        <img v-if="dataEvents?.templateLookUp[0].images_cover != null && dataEvents?.templateLookUp[0].images_cover != ''" :src="dataEvents?.templateLookUp[0].images_cover" alt="" class="img-fluid cover-img">
                        <img v-else src="@/assets/images/default-cover.png" alt="default" class="img-fluid cover-img">
                        <div class="text-left ml-2 w-100">
                            <div class="ml-2 mt-1">
                                <h3>{{dataEvents?.quiz_name}}</h3>
                                <small>{{ dataEvents?.templateLookUp[0].description != null ? dataEvents?.templateLookUp[0].description : '' }}</small>
                            </div>
                            <div class="ml-2 mt-1">
                                <div class="d-flex justify-content-start my-3">
                                    <div class="d-flex align-items-center">
                                        <img src="@/assets/images/icons/ic_play_grup_grey.svg" alt="" class="img-fluid" />
                                        <p class="ml-1 text-none">{{dataEvents?.templateLookUp[0].total_play ? dataEvents?.templateLookUp[0].total_play : '-'}}</p>
                                    </div>
                                    <div class="d-flex align-items-center ml-3 ps-0">
                                        <img src="@/assets/images/icons/ic_question_grup_grey.svg" alt="" class="img-fluid" />
                                        <p class="ml-1 text-none">{{ dataQuestions.length }}</p>
                                    </div>
                                    <div class="d-flex align-items-center ml-3">
                                        <img src="@/assets/images/icons/ic_clock_grup_grey.svg" alt="" class="img-fluid" />
                                        <p class="ml-1 text-none">{{getMinQuiz(dataQuestions.length)}} min</p>
                                    </div>
                                    <!-- <p class="ml-1 text-none">| Grade Elementary 1SD - 2SD |</p>
                                    <p class="ml-1 text-none">Point : 100</p> -->
                                </div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="d-flex align-items-center">
                                    <div class="creator-info d-flex align-items-center">
                                        <div class="mx-2 d-flex align-items-center">
                                            <img class="rounded-circle" src="@/assets/images/logo/soca-logo.png" alt="">
                                            <!-- <img :src="dataEvents?.data_creator[0].picture != null ? dataQuiz.data_creator[0].picture : 'https://ui-avatars.com/api/?background=405DC3&color=fff&name=' + dataEvents?.creatorLookup[0].creator_name" alt=""> -->
                                            <div class="d-flex align-items-end mx-1">
                                                <small>{{ dataEvents?.templateLookUp[0].author }}</small>
                                                <span style="font-size:10px;">, {{getDate(dataEvents?.templateLookUp[0].createdAt)}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <img src="@/assets/images/icons/ic_star_fill.svg" alt="" class="img-fluid ml-3">
                                    <p class="ml-1 mb-0 text-white">{{ dataEvents?.templateLookUp[0].rating ? dataEvents?.templateLookUp[0].rating : '0' }}</p>
                                </div>
                                <button class="btn btn-outline-purple btn-md" @click="unduhLaporan(dataEvents?.quiz_name)">Export to Excel</button>
                            </div>
                        </div>
                    </div>
                    <div v-else class="d-flex mx-4 my-4 align-items-center">
                        <div class="d-flex align-items-center w-100">
                            <img v-if="dataEvents?.creatorLookup[0]?.images_cover != null && dataEvents?.creatorLookup[0]?.images_cover != 'undefined'" :src="dataEvents?.creatorLookup[0]?.images_cover" alt="" class="img-fluid cover-img">
                            <img v-else src="@/assets/images/default-cover.png" alt="default" class="img-fluid cover-img">
                            <div class="text-left ml-2 w-100">
                                <div class="ml-2 mt-1">
                                    <h3>{{dataEvents?.quiz_name}}</h3>
                                    <small>{{ dataEvents?.creatorLookup[0].description != null ? dataEvents?.creatorLookup[0].description : '' }}</small>
                                </div>
                                <div class="ml-2 mt-1">
                                    <div class="d-flex justify-content-start my-3">
                                        <div class="d-flex align-items-center">
                                            <img src="@/assets/images/icons/ic_play_grup_grey.svg" alt="" class="img-fluid" />
                                            <p class="ml-1 text-white mb-0">{{dataEvents?.creatorLookup[0].total_play ? dataEvents?.creatorLookup[0].total_play : '-'}}</p>
                                        </div>
                                        <div class="d-flex align-items-center ml-3 ps-0">
                                            <img src="@/assets/images/icons/ic_question_grup_grey.svg" alt="" class="img-fluid" />
                                            <p class="ml-1 text-white mb-0">{{ dataQuestions.length }}</p>
                                        </div>
                                        <div class="d-flex align-items-center ml-3">
                                            <img src="@/assets/images/icons/ic_clock_grup_grey.svg" alt="" class="img-fluid" />
                                            <p class="ml-1 text-white mb-0">{{getMinQuiz(dataQuestions.length)}} min </p>
                                        </div>
                                        <!-- <p class="ml-1 text-none">| Grade Elementary 1SD - 2SD |</p>
                                        <p class="ml-1 text-none">Point : 100</p> -->
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="d-flex align-items-center">
                                        <div class="creator-info d-flex align-items-center">
                                            <div class="mx-2 d-flex align-items-center">
                                                <img class="rounded-circle" :src="'https://ui-avatars.com/api/?background=405DC3&color=fff&name=' + dataEvents?.creatorLookup[0].creator_name" alt="">
                                                <!-- <img :src="dataEvents?.data_creator[0].picture != null ? dataQuiz.data_creator[0].picture : 'https://ui-avatars.com/api/?background=405DC3&color=fff&name=' + dataEvents?.creatorLookup[0].creator_name" alt=""> -->
                                                <div class="d-flex align-items-end mx-1">
                                                    <small>{{ dataEvents?.creatorLookup[0].creator_name }}</small>
                                                    <span style="font-size:10px;">, {{getDate(dataEvents?.creatorLookup[0].createdAt)}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <img src="@/assets/images/icons/ic_star_fill.svg" alt="" class="img-fluid ml-3">
                                        <p class="ml-1 mb-0 text-white">{{ dataEvents?.creatorLookup[0].rating ? dataEvents?.creatorLookup[0].rating : '0' }}</p>
                                    </div>
                                    <button class="btn btn-outline-purple btn-md btn-impor" @click="unduhLaporan(dataEvents?.quiz_name)">Export to Excel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container-fluid content-list-peserta">
                        <vs-tabs :color="colorx">
                            <vs-tab label="Participants">
                                <div class="peserta-tab">
                                    <div class="row my-2">
                                        <div class="col text-left">
                                            <div class="d-flex ml-3 align-items-center">
                                                <div class="mr-2">
                                                    <p class="m-0">Sort By :</p>
                                                </div>
                                                <div class="text-left">
                                                    <select name="" id="filter" class="form-control mr-3">
                                                        <option value="">Highest score</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col text-right">
                                            <div class="d-inline-flex align-items-center mr-2">
                                            Show&nbsp;
                                            <select class="form-control"
                                                v-model="pesertaPerpage"
                                            >
                                                <option v-for="(data, index) in pageOptions" :key="index" :value="data">{{ data }}</option>
                                            </select
                                            >&nbsp;entries
                                            </div>
                                        </div>
                                    </div>
                                    <div class="" v-for="(data, index) in pesertaList" :key="index">
                                        <div class="card list-peserta my-3 container-fluid" >
                                            <div class="row align-items-center mr-3 my-2">
                                                <div class="col-5">
                                                    <div class="container-fluid">
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="pl-4 d-flex align-items-center py-1">
                                                                    <p class="mb-0 mr-5 text-nowrap"># {{ (currentPagePeserta - 1) * pesertaPerpage + index + 1 }}</p>
                                                                    <img :src="'https://ui-avatars.com/api/?background=405DC3&color=fff&name=' + data.user_name" alt="" class="rounded-circle pict-sm">
                                                                    <p class="m-0 ml-3 mr-1 text-truncate">{{ data.user_name }}</p>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 d-flex align-items-center">
                                                                <img src="@/assets/images/logo/piala.svg" alt="" class="">
                                                                <p class="m-0 ml-3 text-success font-weight-bold">{{data.score}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-7">
                                                    <div class="progress mr-xl-5">
                                                        <div class="progress-bar bg-success" role="progressbar" :style="{width: (data.data_true_answer.length / data.data_all_answer.length) * 100 + '%', ariaValuenow : '25', ariaValuemin: '0', ariaValuemax:'100'}">Correct {{data.data_true_answer.length}}</div>
                                                        <div class="progress-bar bg-danger" role="progressbar" :style="{width: (data.data_false_answer.length / data.data_all_answer.length) * 100 + '%', ariaValuenow : '25', ariaValuemin: '0', ariaValuemax:'100'}">Incorrect {{data.data_false_answer.length}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pagination-seeall container-fluid mt-3 d-flex justify-content-between">
                                        <p>Showing {{pesertaList.length}} of {{dataResult.length}} entries</p>
                                        <b-pagination
                                            v-model="currentPagePeserta"
                                            :total-rows="rowsPeserta"
                                            :per-page="pesertaPerpage"
                                            aria-controls="allData"
                                            prev-text="Prev"
                                            next-text="Next"
                                        ></b-pagination>
                                    </div>
                                </div>
                            </vs-tab>
                            <vs-tab label="Questions">
                                <div class="pertanyaan-tab">
                                    <div class="d-flex justify-content-between px-2 py-3">
                                        <div class="question-total d-flex align-items-center">
                                            <font-awesome-icon icon="fa-regular fa-file-alt" />
                                            <h5 class="font-weight-normal ml-2">{{ dataQuestions.length }} Questions</h5>
                                        </div>
                                        <div class="d-flex ml-3 align-items-center">
                                            <div class="d-inline-flex align-items-center mr-2">
                                            Show&nbsp;
                                            <select class="form-control"
                                                v-model="pertanyaanPerpage"
                                            >
                                                <option v-for="(data, index) in pageOptions" :key="index" :value="data">{{ data }}</option>
                                            </select
                                            >&nbsp;entries
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-for="(data,index) in pertanyaanList" :key="index" v-katex:auto>
                                    <div class="card my-3">
                                        <div>
                                            <div class="d-flex align-items-center px-4 pt-3">
                                                <p v-if="data.question_type !== 'Code Test'" class="mb-0">{{ index + 1 }}.&nbsp;&nbsp;</p>
                                                <!-- <img v-if="data.image != null" :src="require(`@/assets/images/${data.image}`)" alt="" class="mr-3"> -->
                                                <img v-if="data.image_url != null && data.image_url != ''" :src="data.image_url" :alt="data.image_url" class="img-fluid mb-3 image-url-style"/>
                                                <audio v-if="data.voice != null && data.voice != ''" :src="data.voice" controls></audio>
                                                <div v-if="data.video != null && data.video != ''" class="text-center w-100">
                                                    <iframe width="50%" height="300" :src="`https://www.youtube.com/embed/${getYoutubeVideoId(data.video)}`" title="YouTube video player" class="mt-2" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                </div>
                                                <!-- <p v-if="data.voice == null || data.voice == '' || data.video == null || data.video == ''" class="text-break text-left mb-0"><span v-html="data.question"></span></p> -->
                                                <div class="w-100 ml-2">
                                                    <div v-if="data.question_type === 'Code Test'" class="mb-3 text-left">
                                                        <p class="mb-3" :style="{ fontSize: '16px' }">Question {{ index + 1 }}</p>
                                                        <CodeEditor
                                                            :language="data.code_language || 'py'"
                                                            :value="data.code"
                                                            readonly
                                                        />
                                                    </div>
                                                    <p class="font-weight-normal text-break d-flex mb-0"><span class="ml-1 text-break question__report" v-html="data.question"></span></p>
                                                </div>
                                            </div>
                                            <hr class="px-4" style="background: #2D2D2D; height: 0.1px" >
                                            <div class="text-left px-4 py-3">
                                                <div class="answer">
                                                    <div class="row mx-1">
                                                        <div class="col-4 text-left">
                                                            <div>
                                                                <p class="d-flex text-break">A. <span class="ml-1 text-break" v-html="data.option_1"></span></p>
                                                            </div>
                                                        </div>
                                                        <div class="col-1">
                                                            <div>
                                                                <p class="text-truncate">{{ data.data_answer_option_1.length }} Participant</p>
                                                            </div>
                                                        </div>
                                                        <div class="col-7">
                                                            <div :class="data.correct_answer == '1' ? 'custom-prog bg-success' : 'custom-prog bg-danger'" :style="{height: 'auto', width: (data.data_answer_option_1.length / data.data_answer_all.length) * 100 + '%', borderRadius: '0px 50px 50px 0px'}">
                                                                <p v-if="data.data_answer_option_1.length != 0" :class="data.correct_answer == '1' ? 'text-success' : 'text-danger'">1</p>
                                                                <p v-else class="text-white">0</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row mx-1">
                                                        <div class="col-4 text-left">
                                                            <div>
                                                                <p class="d-flex text-break">B. <span class="ml-1 text-break" v-html="data.option_2"></span></p>
                                                            </div>
                                                        </div>
                                                        <div class="col-1">
                                                            <div>
                                                                <p class="text-truncate">{{ data.data_answer_option_2.length }} Participant</p>
                                                            </div>
                                                        </div>
                                                        <div class="col-7">
                                                            <div :class="data.correct_answer == '2' ? 'custom-prog bg-success' : 'custom-prog bg-danger'" :style="{height: 'auto', width: (data.data_answer_option_2.length / data.data_answer_all.length) * 100 + '%', borderRadius: '0px 50px 50px 0px'}">
                                                                <p v-if="data.data_answer_option_2.length != 0" :class="data.correct_answer == '2' ? 'text-success' : 'text-danger'">1</p>
                                                                <p v-else class="text-white">0</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="data.question_type != 'True or False'" class="row mx-1">
                                                        <div class="col-4 text-left">
                                                            <div>
                                                                <p class="d-flex text-break">C. <span class="ml-1 text-break" v-html="data.option_3"></span></p>
                                                            </div>
                                                        </div>
                                                        <div class="col-1">
                                                            <div>
                                                                <p class="text-truncate">{{ data.data_answer_option_3.length }} Participant</p>
                                                            </div>
                                                        </div>
                                                        <div class="col-7">
                                                            <div :class="data.correct_answer == '3' ? 'custom-prog bg-success' : 'custom-prog bg-danger'" :style="{height: 'auto', width: (data.data_answer_option_3.length / data.data_answer_all.length) * 100 + '%', borderRadius: '0px 50px 50px 0px'}">
                                                                <p v-if="data.data_answer_option_3.length != 0" :class="data.correct_answer == '3' ? 'text-success' : 'text-danger'">1</p>
                                                                <p v-else class="text-white">0</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="data.question_type != 'True or False'" class="row mx-1">
                                                        <div class="col-4 text-left">
                                                            <div>
                                                                <p class="d-flex text-break">D. <span class="ml-1 text-break" v-html="data.option_4"></span></p>
                                                            </div>
                                                        </div>
                                                        <div class="col-1">
                                                            <div>
                                                                <p class="text-truncate">{{ data.data_answer_option_4.length }} Participant</p>
                                                            </div>
                                                        </div>
                                                        <div class="col-7">
                                                            <div :class="data.correct_answer == '4' ? 'custom-prog bg-success' : 'custom-prog bg-danger'" :style="{height: 'auto', width: (data.data_answer_option_4.length / data.data_answer_all.length) * 100 + '%', borderRadius: '0px 50px 50px 0px'}">
                                                                <p v-if="data.data_answer_option_4.length != 0" :class="data.correct_answer == '4' ? 'text-success' : 'text-danger'">1</p>
                                                                <p v-else class="text-white">0</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pagination-seeall container-fluid mt-3 d-flex justify-content-between">
                                    <p>Showing {{pertanyaanList.length}} of {{dataQuestions.length}} entries</p>
                                    <b-pagination
                                        v-model="currentPagePertanyaan"
                                        :total-rows="rowsPertanyaan"
                                        :per-page="pertanyaanPerpage"
                                        aria-controls="allData"
                                        prev-text="Prev"
                                        next-text="Next"
                                    ></b-pagination>
                                </div>
                            </vs-tab>
                        </vs-tabs>
                    </div>
                </div>
            </div>
        </div>

        <b-modal id="modal-detail-question" size="lg" hide-footer centered header-class="border-0 pt-4 pb-0" footer-class="border-0 pb-3 pt-0 mx-auto">
            <template #modal-title>
                Pilihan Ganda
            </template>
            <div class="content-modal">
                <vs-tabs>
                    <vs-tab label="Semua">
                        <div v-for="(data, index) in dummyModalData" :key="index" class="py-2">
                            <div class="card">
                                <div class="container-md px-5 py-3">
                                    <div class="row d-flex align-items-center justify-content-between">
                                        <div class="col">
                                            <div class="d-flex align-items-center">
                                                <img src="@/assets/images/users/2.png" alt="" style="width:33px; height:33px;" class="rounded-circle">
                                                <p class="mb-0 ml-2">{{ data.nama }}</p>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="d-flex align-items-center justify-content-end">
                                                <b-icon :icon="data.status ? 'check-circle' : 'x-circle'" :class="data.status ? 'text-success' : 'text-danger'"></b-icon>
                                                <p v-if="data.status" class="m-0 text-none ml-1 text-success mr-3">Benar</p>
                                                <p v-else class="m-0 text-none ml-1 text-danger mr-3">Salah</p>
                                                <div class="form-point mr-3">
                                                    <p class="mb-0 my-1 mx-3">{{ data.poin }}</p>
                                                </div>
                                                <div class="form-point">
                                                    <p class="mb-0 my-1 mx-3">{{ data.waktu }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                    <p class="text-none">Participant Answers</p>
                                    <p>{{ data.jawaban }}</p>
                                </div>
                            </div>
                        </div>
                    </vs-tab>
                    <vs-tab label="Benar">
                        <div v-for="(data, index) in dummyModalData" :key="index" class="py-2">
                            <div v-if="data.status" class="card">
                                <div class="container-md px-5 py-3">
                                    <div class="row d-flex align-items-center justify-content-between">
                                        <div class="col">
                                            <div class="d-flex align-items-center">
                                                <img src="@/assets/images/users/2.png" alt="" style="width:33px; height:33px;" class="rounded-circle">
                                                <p class="mb-0 ml-2">{{ data.nama }}</p>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="d-flex align-items-center">
                                                <b-icon :icon="data.status ? 'check-circle' : 'x-circle'" :class="data.status ? 'text-success' : 'text-danger'"></b-icon>
                                                <p v-if="data.status" class="m-0 text-none ml-1 text-success mr-3">Benar</p>
                                                <p v-else class="m-0 text-none ml-1 text-danger mr-3">Salah</p>
                                                <input type="text" class="form-control mr-3" readonly :placeholder="data.poin">
                                                <input type="text" class="form-control mr-3" readonly :placeholder="data.waktu">
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                    <p class="text-none">Participant Answers</p>
                                    <p>{{ data.jawaban }}</p>
                                </div>
                            </div>
                        </div>
                    </vs-tab>
                    <vs-tab label="Salah">
                        <div v-for="(data, index) in dummyModalData" :key="index" class="py-2">
                            <div v-if="!data.status" class="card">
                                <div class="container-md px-5 py-3">
                                    <div class="row d-flex align-items-center justify-content-between">
                                        <div class="col">
                                            <div class="d-flex align-items-center">
                                                <img src="@/assets/images/users/2.png" alt="" style="width:33px; height:33px;" class="rounded-circle">
                                                <p class="mb-0 ml-2">{{ data.nama }}</p>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="d-flex align-items-center">
                                                <b-icon :icon="data.status ? 'check-circle' : 'x-circle'" :class="data.status ? 'text-success' : 'text-danger'"></b-icon>
                                                <p v-if="data.status" class="m-0 text-none ml-1 text-success mr-3">Benar</p>
                                                <p v-else class="m-0 text-none ml-1 text-danger mr-3">Salah</p>
                                                <input type="text" class="form-control mr-3" readonly :placeholder="data.poin">
                                                <input type="text" class="form-control mr-3" readonly :placeholder="data.waktu">
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                    <p class="text-none">Participant Answers</p>
                                    <p>{{ data.jawaban }}</p>
                                </div>
                            </div>
                        </div>
                    </vs-tab>
                </vs-tabs>
            </div>
        </b-modal>
        <Soca v-if="loading_event || loading_question || loading_result"/>
    </section>
</template>
<script>
import {getQuizEventsDetail, getQuizEventsDetailQuestions, imporSoal} from '@/services/quiz-events/quiz-events.service'
import {getQuizResultsByEvent} from '@/services/quiz-results/quiz-results.service'
import { exportUtils } from "@/mixins/ExportUtils";

import Soca from '@/components/loading/Soca'
import CodeEditor from '@/components/code-editor/CodeEditor';
import $ from "jquery";

export default {
    components: {
        Soca,
        CodeEditor,
    },
     mixins: [exportUtils],

    data(){
        return {
            colorx: "#6D75F6",
            pageOptions: [10, 25, 50, 100],
            pesertaPerpage: 10,
            pertanyaanPerpage: 10,
            currentPagePeserta : 1,
            currentPagePertanyaan : 1,
            dataPeserta : [
                {
                    nama : 'Asep Bunglon',
                },
                {
                    nama : 'Dadang Karbit'
                },
                {
                    nama : 'Markus Spirtus'
                },
                {
                    nama : 'Abu Gosok'
                },
            ],
            dummyData : [
                {
                    no : 1,
                    pertanyaan : 'Siapakah tokoh yang ada pada gambar disamping?',
                    jawaban : ['A. Dilan', 'B. Engkus', 'C. BJ. Habiebie', 'D. Ir.Soekarno'],
                    poin : '10',
                    waktu : '20 Seconds',
                    image : 'soekarno.png',
                    tipe : 'pilihan-ganda'
                },
                {
                    no : 2,
                    pertanyaan : 'Ibu kota Jawa Barat adalah Bekasi',
                    jawaban : ['Benar', 'Salah'],
                    poin : '0',
                    waktu : '20 Seconds',
                    image : null,
                    tipe : 'benar-salah'
                },
                {
                    no : 3,
                    pertanyaan : 'Siapakah tokoh yang ada pada gambar disamping?',
                    jawaban : ['A. Dilan', 'B. Engkus', 'C. BJ. Habiebie', 'D. Ir.Soekarno'],
                    poin : '10',
                    waktu : '25 Seconds',
                    image : 'soekarno.png',
                    tipe : 'pilihan-ganda'
                },
                {
                    no : 4,
                    pertanyaan : 'Ibu kota Jawa Barat adalah Bekasi',
                    jawaban : ['Benar', 'Salah'],
                    poin : '0',
                    waktu : '30 Seconds',
                    image : null,
                    tipe : 'benar-salah'
                },
            ],
            dummyModalData : [
                {
                    nama : 'Asep Balon',
                    jawaban : 'D. Ir. Soekarno',
                    poin : '10 Points',
                    waktu : '10 Seconds',
                    status : true,
                },
                {
                    nama : 'Markus Spirtus',
                    jawaban : 'D. Ir. Soekarno',
                    poin : '10 Points',
                    waktu : '20 Seconds',
                    status : true,
                },
                {
                    nama : 'Maman Delon',
                    jawaban : 'C. BJ. Habiebie',
                    poin : '0 Points',
                    waktu : '30 Seconds',
                    status : false,
                },
                {
                    nama : 'Dadan Kasbon',
                    jawaban : 'A. Dilan',
                    poin : '0 Points',
                    waktu : '30 Seconds',
                    status : false,
                },
            ],
        dataEvents: [
            {
                creatorLookup : [],
                templateLookUp : [],
            }
        ],
        dataResult: [],
        dataQuestions: [],

        loading_event: false,
        loading_result: false,
        loading_question: false
        }
    },
    async mounted(){
        setTimeout(() => {
            $("section.detail-report-quiz").parent(".main-container-fluid").addClass("m-0");
        }, 500);
        const id = this.$route.query.event_id
        await this.getQuizEventsDetail(id)
        await this.setSideBar()
        await this.getQuizResultsByEvent(id)
        await this.getQuizEventsDetailQuestions(id)
    },
    computed: {
        rowsPeserta(){
            return this.dataResult.length
        },

        pesertaList(){
            return this.dataResult.slice((this.currentPagePeserta -1) * this.pesertaPerpage, this.currentPagePeserta * this.pesertaPerpage)
        },

        rowsPertanyaan(){
            return this.dataQuestions.length
        },

        pertanyaanList(){
            return this.dataQuestions.slice((this.currentPagePertanyaan -1) * this.pertanyaanPerpage, this.currentPagePertanyaan * this.pertanyaanPerpage)
        }
    },
    methods: {

        setSideBar() {
            this.$store.commit("IS_SIDEBAR_ACTIVE", false);
        },

        detailQuestionsParticipant(){
            this.$bvModal.show('modal-detail-question')
        },

        async getQuizEventsDetail(id){
            this.loading_event = true
            await getQuizEventsDetail(id).then((response)=>{
                    if(response.status){
                        this.$vs.loading.close();
                        this.dataEvents = response.data[0];
                        this.loading_event = false
                        console.log(this.dataEvents)
                    } else {
                        this.$vs.loading.close();
                        this.dataEvents = [];
                        this.loading_event = false
                    }

                }).catch((err)=>{
                    this.$vs.loading.close();
                    console.log("data err", err)
                })
        },
        unduhLaporan(quiz_name){
            const id = this.$route.query.event_id

            imporSoal(id)
            .then((response) => {
                this.exportData(response.data, "Laporan Quiz " + quiz_name)
            })
        },
        async getQuizEventsDetailQuestions(id){
            this.loading_question = true
            this.dataQuestions = []
            await getQuizEventsDetailQuestions(id).then((response)=>{
                    if(response.status){
                        this.$vs.loading.close();
                        let data = response.data;
                        data.forEach((e) => {
                            if(e.question_type != 'blank') {
                                this.dataQuestions.push(e)
                            }
                        })
                        this.loading_question = false
                    } else {
                        this.$vs.loading.close();
                        this.dataQuestions = [];
                        this.loading_question = false
                    }

                }).catch((err)=>{
                    this.$vs.loading.close();
                    console.log("data err", err)
                })
        },
        async getQuizResultsByEvent(id){
            this.loading_result = true
            await getQuizResultsByEvent(id).then((response)=>{
                    if(response.status){
                        this.$vs.loading.close();
                        this.dataResult = response.data;
                        this.loading_result = false
                    } else {
                        this.$vs.loading.close();
                        this.dataResult = [];
                        this.loading_result = false
                    }

                }).catch((err)=>{
                    this.$vs.loading.close();
                    console.log("data err", err)
                })
        },
        detailLaporanPeserta(quiz_result_id){
            this.$router.push({ name: 'Detail-Report-Peserta', query: {
                        event_id: this.$route.query.event_id,
                        quiz_result_id: quiz_result_id
                    }})
        },
        getMinQuiz(length){

            let time = length * 30

            let min = Math.floor(time / 60);

            return min
        },
        getDate(item){
            var publish = new Date(item);

            var seconds = Math.floor((new Date() - publish) / 1000);

            var interval = seconds / 31536000;

            if (interval > 1) {
                return Math.floor(interval) + " years ago";
            }
            interval = seconds / 2592000;
            if (interval > 1) {
                return Math.floor(interval) + " months ago";
            }
            interval = seconds / 86400;
            if (interval > 1) {
                return Math.floor(interval) + " days ago";
            }
            interval = seconds / 3600;
            if (interval > 1) {
                return Math.floor(interval) + " hours ago";
            }
            interval = seconds / 60;
            if (interval > 1) {
                return Math.floor(interval) + " minutes ago";
            }
            return Math.floor(seconds) + " seconds ago";
        },
        getYoutubeVideoId(url) {
            let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
            let match = url.match(regExp);
            return (match && match[7].length === 11) ? match[7] : '';
        },
    },
}
</script>
<style scoped>
.image-url-style{
    width: 6rem;
    height: 6rem;
}
.detail-report img, .detail-report .card{
    border-radius: 15px;
}

.user-svg{
    width: 10px;
    height: 13px;
}

.paper-svg, .time-square-svg{
    width: 11px;
    height: 13.55px;
}

.report-quiz .text-none {
    color: #8C8C8C;
    margin: 0;
    font-size: 12px;
}
.btn-impor{
    border-radius: 15px;
}

.pict-sm{
    width: 33px;
    height: 33px;
    background-repeat: no-repeat;
    background-position: center center;
}

.peserta-tab .card {
    border-radius: 10px !important;
}

.content-modal .card{
    border-radius: 10px;
}

.content-modal .card input::-webkit-input-placeholder{
    text-align: center;
}

.text-none{
    color: #8C8C8C;
    margin: 0;
    font-size: 12px;
}

.cover-img{
    min-width: 11rem;
    height: 8.75rem;
    border-radius: 10px;
}

.creator-info{
    height: 33px;
    width: auto;
    background-color:#8C8C8C;
    border-radius: 17px;
}

.creator-info img {
  width: 18px;
  height: 18px;
}

.btn-outline-purple{
    color: #6D75F6;
    border: 2px solid #6D75F6;
    border-radius: 20px;
    background: transparent;
}

.form-control{
    background-color: #222222;
    color: white;
    border-color: #383838;
}

.content-list-peserta {
    background: black;
}

.progress {
  background-color: #222222;
}

.form-point{
    width: auto;
    background-color:#8C8C8C;
    border-radius:4px ;
}

</style>

<style>
#modal-detail-question .modal-content{
    color: white;
    background-color: #1C1C1C;
}
.question__report p {
    margin-bottom: 0 !important;
}
</style>